/* eslint-disable no-unused-vars */
import { FlexCols, FlexItem, FlexRows } from "lib/core/flex"
import { styled, css } from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';

import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlBadge from '@shoelace-style/shoelace/dist/react/badge';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlTab from '@shoelace-style/shoelace/dist/react/tab';
import SlTabGroup from '@shoelace-style/shoelace/dist/react/tab-group';
import SlTabPanel from '@shoelace-style/shoelace/dist/react/tab-panel';

import { TableView } from "lib/components/table";
import { Skeleton, NoData } from "routes/main";
import PannelSmall from "lib/components/panel-sm";

import useSessionData from "stores/useSessionData";
import {
  getFindingDetails
} from "lib/core/bera";

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-flow: row wrap;
  flex: 1;
  overflow-y: scroll;
  border-right: 1px solid ${({ theme }) => theme.color.border};
`;

const StyledMenuItem = styled.a`
  padding: var(--mx-navmenu-item-padding);
  border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.background};;
  text-decoration: none;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.text};
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s linear;
  height: 54px;

  border-bottom: 1px dotted ${({ theme }) => theme.color.border};

  &:hover {
    background-color: ${({ theme }) => theme.color.hoverBackground};
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.hoverBackground};
  }

  ${props => props.$current && css`
    border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    &:hover {
      border-left: var(--mx-navmenu-item-border) solid ${({ theme }) => theme.color.selected};
    }
  `}

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 550px) {
    width: 50%;
  }
`;

const StyledRows = styled(FlexRows)`
  border-radius: 0px;
  height: 65vh;
`

const Title = styled.h2`
`

const SubTitle = styled.h3`
  display: inline-block;
`

const Panel = styled.div`
  height: ${props => props.$ht || "74vh"};
  overflow-y: auto;
  margin: 0px -10px;
  padding: 20px;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;
  min-height: 60px;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`

const RowItem = styled(FlexCols)`
  cursor: pointer;
  width: 32%;
  min-width: 300px;
  height: 120px;
  border-radius: 6px;
  padding: 15px;
  gap: 16%;
  transition: transform 0.3s ease 0s;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px;
  }
`
const Label = styled.h4``
const Text = styled.span``

const columns = [
  {
    field: "workspace",
    display: "Workspace"
  },
  {
    field: "subdomain",
    display: "Subdomain"
  },
  {
    field: "desc",
    display: "Description"
  }
];

const options = {
  checkbox: false,
  toolbar: true,
  tooltip: false,
  ipp: false
}

function FormatName(name) {
  if (name !== name.toLowerCase())
    return name

  var words = name.split("-")
  var result = []

  for (const word of words) {
    if (word.length < 5)
      result.push(word.toUpperCase())
    else
      result.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  }
  return result.join(" ")
}


export const Details = (props) => {
  const [search, setSearch] = useState("");
  const [severity, setSeverity] = useState([])
  const [item, setItem] = useState(null);
  const [details, setDetails] = useState(null)
  const [rows, setRows] = useState(resource?.data?.items?.filter(filterRows).sort(sortRows))
  const [sevrityCount, setSeverityCount] = useState([0, 0, 0, 0, 0])
  const [selected, setSelected] = useState("ID-00001");
  const [selectedIdx, setSelectedIdx] = useState(0)
  const [open, setOpen] = useState(false);

  var args = {
    slice_name: "finding",
    store_name: "findings"
  }

  var resource = useSessionData(args)

  useEffect(() => {
    var filteredRows = resource?.data?.items?.filter(filterRows).sort(sortRows)
    setRows(filteredRows)

    var curSel = selected
  
    if (filteredRows) {
      setSelected(filteredRows[0]?.id)
      curSel = filteredRows[0]?.id
    }

    var item = resource?.data?.items?.filter(d => d.id === curSel)[0]
    getFinding(item?.type, item?.name)
    setItem(item)

    evalSeverityCount()
  }, [resource]);

  useEffect(() => {
    var item = resource?.data?.items?.filter(d => d.id === selected)[0]
    getFinding(item?.type, item?.name)
    setItem(item)
  }, [selected]);

  useEffect(() => {
    evalSeverityCount()
    var filteredRows = resource?.data?.items?.filter(filterRows).sort(sortRows)
    if (filteredRows) {
      if (filteredRows?.length > 0) {
        var item = filteredRows[0]
        // getFinding(item?.type, item?.name)
        setItem(item)
        setSelected(item?.id)
        setSelectedIdx(0)
      }
      setRows(filteredRows)
    }
  }, [search, severity])

  function evalSeverityCount() {
    var sc = [0, 0, 0, 0, 0]
    resource?.data?.items?.filter((row) => {
      var result = (search.length < 3) ? true : row.name.toLowerCase().includes(search.toLowerCase())

      if (result) {
        sc[0] += row.severity === "P1 - CRITICAL" ? 1 : 0
        sc[1] += row.severity === "P2 - HIGH" ? 1 : 0
        sc[2] += row.severity === "P3 - MEDIUM" ? 1 : 0
        sc[3] += row.severity === "P4 - LOW" ? 1 : 0
        sc[4] += row.severity === "P5 - INFO" ? 1 : 0
      }

      return result
    })
    setSeverityCount(sc)
  }

  function handleRequestClose(event) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }

  function TopPanel(props) {
    var stats = [
      {
        title: "Critical",
        stat: props?.severity[0]
      }, {
        title: "High",
        stat: props?.severity[1]
      }, {
        title: "Medium",
        stat: props?.severity[2]
      }, {
        title: "Low",
        stat: props?.severity[3]
      }, {
        title: "Info",
        stat: props?.severity[4]
      }
    ]

    return (
      <>
        {
          <PannelSmall data={stats}></PannelSmall>
        }
      </>
    )
  }

  const [assetOpen, setAssetOpen] = useState(true)

  function Vulnerabilities(props) {

    if (!item || !details) {
      return <Skeleton />
    }

    return ((item && details) &&
      <FlexCols $g10>
        {
          props?.title && (
            <>
              <FlexRows $jcsb>
                <Title style={{ marginTop: "25px" }}>{FormatName(item.name)}</Title>
                <FlexItem style={{ marginTop: "25px" }}>
                  <SubTitle>Severity &nbsp;&nbsp;</SubTitle>
                  <SlBadge variant="neutral" pill>
                    {item.severity}
                  </SlBadge>
                </FlexItem>
                <FlexItem>
                  {selectedIdx + 1} of {rows?.length}
                  <SlButtonGroup style={{ margin: "15px" }}>
                    <SlButton size="medium" onClick={(e) => clickPrev(e)}>
                      <SlIcon name="arrow-left"></SlIcon>
                    </SlButton>
                    <SlButton size="medium" onClick={(e) => clickNext(e)}>
                      <SlIcon name="arrow-right"></SlIcon>
                    </SlButton>
                  </SlButtonGroup>
                </FlexItem>
              </FlexRows>
              <SlDivider />
              <br />
            </>
          )
        }

        <FlexRows>
          <SubTitle>Title</SubTitle>
        </FlexRows>
        {
          details?.data && (
            <>
              <FlexRows>
                {
                  details?.data?.documents[item.name]?.enchantments.short_description ?
                    details?.data?.documents[item.name]?.enchantments.short_description :
                    details?.data?.documents[item.name]?.title
                }
              </FlexRows>
              <br />
            </>
          )
        }
        {
          details?.description && (
            <>
              <FlexRows>
                {
                  details?.description?.brief
                }
              </FlexRows>
              <br />
            </>
          )
        }
        <FlexRows>
          <SubTitle>Description</SubTitle>
        </FlexRows>
        <FlexRows>
          {
            details?.data ?
              details?.data?.documents[item.name]?.description :
              details?.description?.detailed
          }
        </FlexRows>

        {
          details?.impact && (
            <>
              <FlexRows>
                <SubTitle>Impact</SubTitle>
              </FlexRows>
              <FlexRows>
                <ul style={{ paddingLeft: "15px" }}>
                  {
                    details?.impact?.map((im, i) => (
                      <li key={i}>{im}</li>
                    ))
                  }
                </ul>
              </FlexRows>
              <br />
            </>
          )}

        {
          details?.mitigation && (
            <>
              <FlexRows>
                <SubTitle>Mitigation</SubTitle>
              </FlexRows>
              <FlexRows>
                <ul style={{ paddingLeft: "15px" }}>
                  {
                    details?.mitigation?.map((im, i) => (
                      <li key={i}>{im}</li>
                    ))
                  }
                </ul>
              </FlexRows>
              <br />
            </>
          )
        }

        <SlTabGroup>

          {
            item.assets && (
              <SlTab slot="nav" panel="assets">
                Assets &nbsp;&nbsp;<SlBadge variant="neutral" pill>{item?.assets?.length}</SlBadge>
              </SlTab>
            )
          }
          {
            details?.data?.documents[item.name]?.cvss && (
              <SlTab slot="nav" panel="cvss">
                CVSS
              </SlTab>
            )
          }
          {
            details?.data?.documents[item.name]?.affectedSoftware && (
              <SlTab slot="nav" panel="software">
                Affected Softwares &nbsp;&nbsp;<SlBadge variant="neutral" pill>{details?.data?.documents[item.name]?.affectedSoftware?.length}</SlBadge>
              </SlTab>
            )
          }

          {
            item.assets && (
              <SlTabPanel name="assets">
                <TableView data={{ columns, rows: item?.assets }} options={options} />
              </SlTabPanel>
            )
          }
          {
            details?.data?.documents[item.name]?.cvss && (
              <SlTabPanel name="cvss">
                <div style={{ padding: "0px 10px" }}>
                  <CVSS_v3 data={details?.data?.documents[item.name]?.cvss3} /><br />
                  <CVSS_v2 data={details?.data?.documents[item.name]?.cvss2} /><br />
                  <CVSS_v1 data={details?.data?.documents[item.name]?.cvss} />
                </div>
              </SlTabPanel>
            )
          }
          {
            details?.data?.documents[item?.name]?.affectedSoftware && (
              <SlTabPanel name="software">
                <FlexCols style={{ padding: "0px 10px" }}>
                  <FlexRows style={{ paddingBottom: "10px" }}>
                    <FlexItem $fx="1"><Label>Name</Label></FlexItem>
                    <FlexItem $fx="1"><Label>Operator</Label></FlexItem>
                    <FlexItem $fx="1"><Label>Version</Label></FlexItem>
                  </FlexRows>

                  {details?.data?.documents[item.name]?.affectedSoftware?.map((software) => (
                    <>
                      <SlDivider />
                      <FlexRows style={{ padding: "10px 0px" }}>
                        <FlexItem $fx="1"><Text>{software?.name}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{software?.operator}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{software?.version}</Text></FlexItem>
                      </FlexRows>
                    </>
                  ))}
                </FlexCols>
              </SlTabPanel>
            )
          }
        </SlTabGroup>
      </FlexCols>
    )
  }

  function CVSS_v1(props) {
    return (
      props?.data && (
        <SlDetails summary="Version 1" open={false}>
          <FlexCols $g10>
            <FlexRows>
              <FlexItem $fx="1"><Label>Score</Label></FlexItem>
              <FlexItem $fx="1"><Label>Vector</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.score}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.vector}</Text></FlexItem>
            </FlexRows>
          </FlexCols>
        </SlDetails>
      )
    )
  }

  function CVSS_v2(props) {
    return (
      props?.data && (
        <SlDetails summary={"Version " + props?.data?.cvssV2?.version} open={false}>
          <FlexCols $g10>
            <FlexRows>
              <FlexItem $fx="1"><Label>Exploitability Score</Label></FlexItem>
              <FlexItem $fx="1"><Label>Impact Score</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.exploitabilityScore}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.impactScore}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Base Score</Label></FlexItem>
              <FlexItem $fx="1"><Label></Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.baseScore}</Text></FlexItem>
              <FlexItem $fx="1"><Text></Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Access Complexity</Label></FlexItem>
              <FlexItem $fx="1"><Label>Access Vector</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.accessComplexity}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.accessVector}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Authentication</Label></FlexItem>
              <FlexItem $fx="1"><Label>Availability Impact</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.authentication}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.availabilityImpact}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Confidentiality Impact</Label></FlexItem>
              <FlexItem $fx="1"><Label>Integrity Impact</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.confidentialityImpact}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV2?.integrityImpact}</Text></FlexItem>
            </FlexRows>
          </FlexCols>
        </SlDetails>
      )
    )
  }

  function CVSS_v3(props) {
    return (
      props?.data && (
        <SlDetails summary={"Version " + props?.data?.cvssV3?.version} open={true}>
          <FlexCols $g10>
            <FlexRows>
              <FlexItem $fx="1"><Label>Base Score</Label></FlexItem>
              <FlexItem $fx="1"><Label>Base Severity</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.baseScore}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.baseSeverity}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Attack Complexity</Label></FlexItem>
              <FlexItem $fx="1"><Label>Attack Vector</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.attackComplexity}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.attackVector}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Availability Impact</Label></FlexItem>
              <FlexItem $fx="1"><Label>Confidentiality Impact</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.availabilityImpact}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.confidentialityImpact}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Integrity Impact</Label></FlexItem>
              <FlexItem $fx="1"><Label>Privileges Required</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.integrityImpact}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.privilegesRequired}</Text></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Label>Scope</Label></FlexItem>
              <FlexItem $fx="1"><Label>User Interaction</Label></FlexItem>
            </FlexRows>
            <FlexRows>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.scope}</Text></FlexItem>
              <FlexItem $fx="1"><Text>{props?.data?.cvssV3?.userInteraction}</Text></FlexItem>
            </FlexRows>
          </FlexCols>
        </SlDetails>
      )
    )
  }

  function getSeverityIcon(severity) {
    switch (severity) {
    case "P1 - CRITICAL":
      return <SlIcon slot="prefix" name="exclamation-octagon" />
    case "P2 - HIGH":
      return <SlIcon slot="prefix" name="exclamation-diamond" />
    case "P3 - MEDIUM":
      return <SlIcon slot="prefix" name="exclamation-triangle" />
    case "P4 - LOW":
      return <SlIcon slot="prefix" name="exclamation-circle" />
    case "P5 - INFO":
    default:
      return <SlIcon slot="prefix" name="info" />;
    }
  }

  function ListView() {
    return (
      <>
        <FlexCols $g15>
          <Panel>
            {
              <FlexCols $g10>
                {rows?.map((item, i) => (
                  <ListItem $bd $pd15 $g20 key={item.id} onClick={(e) => clickHandler(e, i, item.id)}>
                    <span style={{ fontSize: '20px' }}>
                      {
                        getSeverityIcon(item.severity)
                      }
                    </span>
                    <FlexItem>{FormatName(item.name)}</FlexItem>
                  </ListItem>
                ))}
              </FlexCols>
            }
          </Panel>
        </FlexCols>
      </>
    )
  }

  function CardView() {
    return (
      <>
        <FlexCols $g15>
          <Panel>
            {
              <FlexRows $g15 $jcsb $wr>
                {rows?.map((item, i) => (
                  <RowItem $bd key={item.id} onClick={(e) => clickHandler(e, i, item.id)}>
                    <FlexItem>
                      <span style={{ fontSize: '20px', paddingRight: "10px" }}>
                        {
                          getSeverityIcon(item.severity)
                        }
                      </span>
                      {FormatName(item.name)}
                    </FlexItem>
                  </RowItem>
                ))}
              </FlexRows>
            }
          </Panel>
        </FlexCols>
      </>
    )
  }

  function SplitView() {
    return (
      <StyledRows $g5 $bd>
        <StyledMenuContainer>
          {rows?.map((item, i) => (
            <StyledMenuItem key={item.id} $current={isSelected(item.id)}
              onClick={(e) => clickHandler(e, i, item.id)}>
              {FormatName(item.name)}
            </StyledMenuItem>
          ))}
        </StyledMenuContainer>
        <FlexItem $pd10 $fx="3" style={{ overflowY: "scroll" }}>
          {
            <Vulnerabilities title />
          }
        </FlexItem>
      </StyledRows>
    )
  }

  function ViewContent() {
    switch (props?.view) {
    case 2:
      return (
        <ListView />
      )
    case 3:
      return (
        <CardView />
      )
    default:
      return (
        <SplitView />
      )
    }
  }

  function isSelected(value) {
    return selected === value;
  }

  function getFinding(type, id) {
    if (type === undefined || id === undefined)
      return

    setDetails(null)

    const args = { "path": { "type": type, "id": id } }
    const resource = getFindingDetails(args).then((res) => {
      setDetails(res?.data)
    })
  }

  function clickHandler(e, key, target) {
    e.preventDefault();
    setSelected(target);
    var item = resource?.data?.items?.filter(d => d.id === target)[0]
    // getFinding(item?.type, item?.name)
    setItem(item)
    setSelectedIdx(key)
    if (props.view != 1) {
      setOpen(true)
    }
  }

  function clickPrev(e) {
    e.preventDefault()

    if (rows && selectedIdx > 0) {
      var prevIdx = selectedIdx - 1
      setSelectedIdx(prevIdx)
      var item = rows[prevIdx]
      // getFinding(item?.type, item?.name)
      setItem(item)
      setSelected(item.id)
    }
  }

  function clickNext(e) {
    e.preventDefault()

    if (rows && ((selectedIdx + 1) < rows?.length)) {
      var nextIdx = selectedIdx + 1
      setSelectedIdx(nextIdx)
      var item = rows[nextIdx]
      // getFinding(item?.type, item?.name)
      setItem(item)
      setSelected(item.id)
    }
  }

  function filterRows(row) {
    var result = true

    if (severity.length > 0) {
      result = severity.includes(row.severity)
    }

    if (search.length < 3) {
      return result
    }

    return (result && row.name.toLowerCase().includes(search.toLowerCase()))
  }

  function sortRows(a, b) {
    if (a.severity > b.severity) {
      return 1;
    }
    if (b.severity > a.severity) {
      return -1;
    }
    return 0;
  }

  function handleSelect(event) {
    event.preventDefault();

    const item = event.detail.item;

    var updatedSeverity = [...severity];
    let index = updatedSeverity.indexOf(item.value);

    if (index != -1 && !item.checked) {
      updatedSeverity.splice(index, 1);
    }

    if (index == -1 && item.checked) {
      updatedSeverity.push(item.value)
    }
    setSeverity(updatedSeverity)
  }

  return (
    <>
      {
        (resource && rows) && (
          <FlexCols $g15>
            <FlexItem>
              <TopPanel severity={sevrityCount} />
            </FlexItem>
            <FlexRows $g10>
              <FlexItem $fx="8">
                <SlInput placeholder="Search" clearable style={{ width: "100%" }} size="medium" onSlInput={e => { setSearch(e.target.value) }}>
                  <SlIcon name="search" slot="prefix" style={{ padding: "15px" }}></SlIcon>
                </SlInput>
              </FlexItem>
              <FlexItem>
                <SlButtonGroup label="Severity">
                  <SlDropdown placement="bottom-end" >
                    <SlButton slot="trigger" caret></SlButton>
                    <SlMenu onSlSelect={e => handleSelect(e)}>
                      <SlMenuItem type="checkbox" value="P1 - CRITICAL">
                        <SlIcon slot="prefix" name="exclamation-octagon" />
                        &nbsp;&nbsp;Critical&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <SlBadge slot="suffix" variant="danger" pill pulse={sevrityCount[0] > 0}>{sevrityCount[0]}</SlBadge>
                      </SlMenuItem>
                      <SlMenuItem type="checkbox" value="P2 - HIGH">
                        <SlIcon slot="prefix" name="exclamation-diamond" />
                        &nbsp;&nbsp;High&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <SlBadge slot="suffix" variant="danger" pill pulse={sevrityCount[1] > 0}>{sevrityCount[1]}</SlBadge>
                      </SlMenuItem>
                      <SlMenuItem type="checkbox" value="P3 - MEDIUM">
                        <SlIcon slot="prefix" name="exclamation-triangle" />
                        &nbsp;&nbsp;Medium&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <SlBadge slot="suffix" variant="warning" pill pulse={sevrityCount[2] > 0}>{sevrityCount[2]}</SlBadge>
                      </SlMenuItem>
                      <SlMenuItem type="checkbox" value="P4 - LOW">
                        <SlIcon slot="prefix" name="exclamation-circle" />
                        &nbsp;&nbsp;Low&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <SlBadge slot="suffix" variant="neutral" pill pulse={sevrityCount[3] > 0}>{sevrityCount[3]}</SlBadge>
                      </SlMenuItem>
                      <SlMenuItem type="checkbox" value="P5 - INFO">
                        <SlIcon slot="prefix" name="info" />
                        &nbsp;&nbsp;Info&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <SlBadge slot="suffix" variant="primary" pill pulse={sevrityCount[4] > 0}>{sevrityCount[4]}</SlBadge>
                      </SlMenuItem>
                    </SlMenu>
                  </SlDropdown>
                </SlButtonGroup>
              </FlexItem>
            </FlexRows>
            {
              <NoData criteria={rows?.length === 0} style={{ padding: "20px" }} />
            }
            {
              rows?.length !== 0 && (
                <ViewContent />
              )
            }
          </FlexCols>
        )
      }
      {
        <NoData criteria={resource?.data?.items === null || rows === undefined} />
      }

      <SlDrawer label={item?.name ? FormatName(item?.name) : "loading..."} onSlRequestClose={handleRequestClose} open={open} onSlAfterHide={() => setOpen(false)} style={{ '--size': '60vw' }}>
        {
          item && (
            <>
              <span slot="header-actions">
                <FlexRows $jcsb>
                  <FlexItem style={{ margin: "22px" }}>
                    <SubTitle>Severity &nbsp;&nbsp;</SubTitle>
                    <SlBadge variant="neutral" pill>
                      {item.severity}
                    </SlBadge>
                  </FlexItem>
                  <FlexItem>
                    {selectedIdx + 1} of {rows?.length}
                    <SlButtonGroup style={{ margin: "15px" }}>
                      <SlButton size="medium" onClick={(e) => clickPrev(e)}>
                        <SlIcon name="arrow-left"></SlIcon>
                      </SlButton>
                      <SlButton size="medium" onClick={(e) => clickNext(e)}>
                        <SlIcon name="arrow-right"></SlIcon>
                      </SlButton>
                    </SlButtonGroup>
                  </FlexItem>
                </FlexRows>
              </span>
              <Vulnerabilities />
            </>
          )
        }

        <SlButton slot="footer" variant="primary" onClick={() => setOpen(false)}>
          Close
        </SlButton>
      </SlDrawer>
    </>
  )
}