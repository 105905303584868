/* eslint-disable no-unused-vars */
import { Page, PageHeader, PageBody, Title, Toolbar, ToolbarItem, FlexCols, FlexRows, FlexItem } from "lib/core/flex"
import PannelSmall from "lib/components/panel-sm";

import styled from "styled-components";
import axios from 'axios';
import React, { useState, Suspense, useEffect, useRef } from 'react';

import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlDrawer from '@shoelace-style/shoelace/dist/react/drawer';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlButtonGroup from '@shoelace-style/shoelace/dist/react/button-group';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlAlert from '@shoelace-style/shoelace/dist/react/alert';
import { SplitProgress } from "lib/charts/splitProgress";
import { Loading } from 'routes/main'
import { NoData } from 'routes/main'
import useSessionData from "stores/useSessionData";
import { getScanInfo } from "lib/core/bera";
import GetSecurityHeader from 'lib/core/security_header';

const Panel = styled.div`
  height: ${props => props.$ht || "68vh"};
  overflow-y: auto;
  margin: 0px -20px;
  padding: 20px;
`

const ListItem = styled(FlexRows)`
  cursor: pointer;
  transition: transform 0.3s ease 0s;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  }
  &:hover .child {
    display: block;
  }
`

const DownloadButton = styled.span`
  display: none;
  padding-top: ${(props) => (props.pd ? '12px' : '0px')};
`

const RowItem = styled(FlexCols)`
  cursor: pointer;
  width: 32%;
  min-width: 300px;
  height: 200px;
  border-radius: 6px;
  padding: 15px;
  gap: 16%;
  transition: transform 0.3s ease 0s;

  &:hover {
    // background-color: ${({ theme }) => theme.color.hoverBackground};
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
  }
  &:hover .child {
    display: block;
  }
`

const StyledToastContainer = styled.div`
`

const Label = styled.h4``
const Text = styled.span``

function ScanList(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [err, setError] = useState(null);

  const success = useRef(null);
  const error = useRef(null);
  const [okmsg, setOkMsg] = useState("")
  const [errmsg, setErrMsg] = useState("")

  const handleDownload = async (event, sid) => {
    event.stopPropagation();

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://api.cloudmatos.ai/console/v1/report/' + sid,
        {
          headers: GetSecurityHeader(),
          responseType: 'blob'
        });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',
        'metos-report-' + sid + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setOkMsg('PDF downloaded successfully!')
      success.current.toast()
    } catch (e) {
      setErrMsg('Failed to download PDF!')
      error.current.toast()
      setError('Error downloading PDF');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="3000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
        <SlAlert ref={error} variant="danger" duration="3000" closable>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Error</strong>
          <br />
          {errmsg}
        </SlAlert>
      </StyledToastContainer>
      <FlexCols $g15>
        <Panel>
          <FlexCols $g10>
            {
              props?.$suspense ?
                <Loading /> :
                props?.data ?
                  props?.data?.map((item, i) => (
                    <ListItem $bd $pd15 $g20 key={i} onClick={() => props?.clickHandler(item?.scan)}>
                      <FlexItem $fx="1.5">
                        <FlexCols>
                          <FlexItem><span style={{ fontSize: "0.9rem" }}>{item?.scan.slice(0, 4)}-{item?.scan.slice(4, 6)}-{item?.scan.slice(6, 8)}</span></FlexItem>
                          <br />
                          <FlexItem>
                            {
                              (() => {
                                const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low
                                return <small style={{ color: "#909090" }}>Workspaces: {item?.fail} / {item?.total} | Findings: {findings} <br />Domains: {item?.assets?.domains} | Subdomains: {item?.assets?.sub_domains} | IPs: {item?.assets?.ips} | Ports: {item?.assets?.ports} </small>
                              })()
                            }
                          </FlexItem>
                        </FlexCols>
                      </FlexItem>
                      <FlexItem $fx="1">
                        {
                          (() => {
                            const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low
                            // + item?.severity_score?.info
                            const critical = parseInt((item?.severity_score?.critical * 100) / findings)
                            const high = parseInt((item?.severity_score?.high * 100) / findings)
                            const medium = parseInt((item?.severity_score?.medium * 100) / findings)
                            const low = parseInt((item?.severity_score?.low * 100) / findings)
                            // const info = parseInt((item?.severity_score?.info * 100) / findings)

                            return <><br /><SplitProgress progressValues={[critical | 0, high | 0, medium | 0, low | 0]} /></>
                          })()
                        }
                      </FlexItem>
                      <FlexItem $fx="0.1">
                        <DownloadButton className="child" pd={true.toString()}>
                          <SlButton onClick={(e) => handleDownload(e, item?.scan)} variant="default" size="medium" circle loading={isLoading}>
                            <SlIcon name="download" />
                          </SlButton>
                        </DownloadButton>
                      </FlexItem>
                    </ListItem>
                  )) :
                  <NoData />
            }
          </FlexCols>
        </Panel>
      </FlexCols>
    </>
  )
}

function ScanCards(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [err, setError] = useState(null);

  const success = useRef(null);
  const error = useRef(null);
  const [okmsg, setOkMsg] = useState("")
  const [errmsg, setErrMsg] = useState("")

  const handleDownload = async (event, sid) => {
    event.stopPropagation();

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://api.cloudmatos.ai/console/v1/report/' + sid,
        {
          headers: GetSecurityHeader(),
          responseType: 'blob'
        });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',
        'metos-report-' + sid + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      setOkMsg('PDF downloaded successfully!')
      success.current.toast()
    } catch (e) {
      setErrMsg('Failed to download PDF!')
      error.current.toast()
      setError('Error downloading PDF');
    } finally {
      setIsLoading(false);
    }
  }
  
  return (
    <>
      <StyledToastContainer>
        <SlAlert ref={success} variant="success" duration="3000" closable>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Success</strong>
          <br />
          {okmsg}
        </SlAlert>
        <SlAlert ref={error} variant="danger" duration="3000" closable>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Error</strong>
          <br />
          {errmsg}
        </SlAlert>
      </StyledToastContainer>
      <FlexCols $g15>
        <Panel>
          <FlexRows $g15 $jcsb $wr>
            {
              props?.$suspense ?
                <Loading /> :
                props?.data ?
                  props?.data?.map((item, i) => (
                    <RowItem $bd key={i} onClick={() => props?.clickHandler(item?.scan)}>
                      <FlexItem>
                        <FlexCols>
                          <FlexRows $jcsb style={{ height: '45px' }}>
                            <FlexItem>
                              <span style={{ fontSize: "0.9rem" }}>
                                {item?.scan.slice(0, 4)}-{item?.scan.slice(4, 6)}-{item?.scan.slice(6, 8)}
                              </span>
                            </FlexItem>
                            <DownloadButton className="child">
                              <SlButton onClick={(e) => handleDownload(e, item?.scan)} variant="default" size="medium" circle loading={isLoading}>
                                <SlIcon name="download" />
                              </SlButton>
                            </DownloadButton>
                          </FlexRows>
                          <FlexItem>
                            {
                              (() => {
                                const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low + item?.severity_score?.info
                                return <small style={{ color: "#909090" }}>Workspaces: {item?.fail} / {item?.total} | Findings: {findings} <br />Domains: {item?.assets?.domains} | Subdomains: {item?.assets?.sub_domains} | IPs: {item?.assets?.ips} | Ports: {item?.assets?.ports} </small>
                              })()
                            }
                          </FlexItem>
                        </FlexCols>
                      </FlexItem>
                      <FlexItem>
                        {
                          (() => {
                            const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low
                            // + item?.severity_score?.info
                            const critical = parseInt((item?.severity_score?.critical * 100) / findings)
                            const high = parseInt((item?.severity_score?.high * 100) / findings)
                            const medium = parseInt((item?.severity_score?.medium * 100) / findings)
                            const low = parseInt((item?.severity_score?.low * 100) / findings)
                            // const info = Number((item?.severity_score?.info * 100) / findings)

                            return <SplitProgress progressValues={[critical | 0, high | 0, medium | 0, low | 0]} />
                          })()
                        }
                      </FlexItem>
                      <FlexItem>
                      </FlexItem>
                    </RowItem>
                  )) :
                  <NoData />
            }
          </FlexRows>
        </Panel>
      </FlexCols>
    </>
  )
}

function ScanInfo(props) {

  return (
    <>
      <FlexCols $g10>
        {
          props?.data ? (
            <>
              <FlexRows>
                <FlexItem $fx="1"><Label>Scan Date</Label></FlexItem>
                <FlexItem $fx="1"><Label>Status</Label></FlexItem>
              </FlexRows>
              <FlexRows>
                <FlexItem $fx="1"><Text>{props?.data?.pass}</Text></FlexItem>
                <FlexItem $fx="1"><Text>{props?.data?.fail}</Text></FlexItem>
              </FlexRows>
              <FlexRows>
                <FlexItem $fx="1"><Label>Start time</Label></FlexItem>
                <FlexItem $fx="1"><Label>Finish time</Label></FlexItem>
              </FlexRows>
              <FlexRows>
                <FlexItem $fx="1"><Text>{props?.data?.start_time}</Text></FlexItem>
                <FlexItem $fx="1"><Text>{props?.data?.finish_time}</Text></FlexItem>
              </FlexRows>
              <FlexRows>
                <FlexItem $fx="1"><Label>Durarion</Label></FlexItem>
              </FlexRows>
              <FlexRows>
                <FlexItem $fx="1"><Text>{props?.data?.duration}</Text></FlexItem>
              </FlexRows>
              {/* <div style={{ height: "10px" }}></div><SlDivider />
              {
                (() => {
                  var [ws, tg, az, fd] = [0, 0, 0, 0]
                  var [c, h, m, l] = [0, 0, 0, 0]

                  props?.data?.workspaces?.forEach((item) => {
                    ws += 1
                    tg += item?.targets
                    az += item?.assets
                    fd += (item?.severity_score?.critical + item?.severity_score?.high +
                      item?.severity_score?.medium + item?.severity_score?.low + item?.severity_score?.info)
                    c += item?.severity_score?.critical
                    h += item?.severity_score?.high
                    m += item?.severity_score?.medium
                    l += item?.severity_score?.low
                  })
                  return (
                    <>
                      <FlexRows>
                        <FlexItem $fx="1"><Label>Workspaces</Label></FlexItem>
                        <FlexItem $fx="1"><Label>Targets</Label></FlexItem>
                      </FlexRows><FlexRows>
                        <FlexItem $fx="1"><Text>{ws}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{tg}</Text></FlexItem>
                      </FlexRows><FlexRows>
                        <FlexItem $fx="1"><Label>Assets</Label></FlexItem>
                        <FlexItem $fx="1"><Label>Findings</Label></FlexItem>
                      </FlexRows><FlexRows>
                        <FlexItem $fx="1"><Text>{az}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{fd}</Text></FlexItem>
                      </FlexRows>
                      <div style={{ height: "10px" }}></div>
                      <SlDivider />
                      <FlexRows>
                        <FlexItem $fx="1"><Label>Critical</Label></FlexItem>
                        <FlexItem $fx="1"><Label>High</Label></FlexItem>
                        <FlexItem $fx="1"><Label>Medium</Label></FlexItem>
                        <FlexItem $fx="1"><Label>Low</Label></FlexItem>
                      </FlexRows>
                      <FlexRows>
                        <FlexItem $fx="1"><Text>{c}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{h}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{m}</Text></FlexItem>
                        <FlexItem $fx="1"><Text>{l}</Text></FlexItem>
                      </FlexRows>
                    </>
                  )
                })()
              }
              <div style={{ height: "10px" }}></div> */}
              <SlDivider />
              <ScanInfoTable workspaces={props?.data?.workspaces} />
              <SlDivider />
            </>
          ) : <Loading />
        }
      </FlexCols>
    </>
  )
}

function ScanInfoTable(props) {
  return (
    <>
      <FlexRows>
        <FlexItem $fx="1"><Label>Workspace</Label></FlexItem>
        <FlexItem $fx="0.6"><Label>Targets</Label></FlexItem>
        <FlexItem $fx="0.6"><Label>Assets</Label></FlexItem>
        <FlexItem $fx="1"><Label>Results</Label></FlexItem>
      </FlexRows>
      <SlDivider />
      <>
        {
          props?.workspaces?.map((item, i) => {
            return (
              <FlexRows key={i}>
                <FlexItem $fx="1"><Text>{item?.name}</Text></FlexItem>
                {/* <FlexItem $fx="0.6"><Text>{item?.targets}</Text></FlexItem>
                <FlexItem $fx="0.6"><Text>{item?.assets}</Text></FlexItem> */}
                <FlexItem $fx="1"><Text>C:{item?.severity_score?.critical}, H:{item?.severity_score?.high}, M:{item?.severity_score?.medium}, L:{item?.severity_score?.low}</Text></FlexItem>
              </FlexRows>
            )
          })
        }
      </>
    </>
  )
}

function ScanDetails(props) {
  const args = { "path": { "sid": String(props.sid) } }

  const [resource, setResource] = useState(null)

  useEffect(() => {
    if (props.sid !== null) {
      getScanInfo(args).then((res) => {
        setResource(res)
      })
    }
  }, [props.sid])

  return (
    <SlDrawer label="Scan Info" open={props.open} onSlAfterHide={() => props.setOpen(false)} style={{ '--size': '40vw' }}>
      <Suspense fallback={<Loading />}>
        <ScanInfo data={resource?.data} />
      </Suspense>
      <SlButton slot="footer" variant="primary" onClick={() => props.setOpen(false)}>
        Close
      </SlButton>
    </SlDrawer>
  )
}

export default function Scans() {
  var args = {
    slice_name: "scan",
    store_name: "items"
  }

  var resource = useSessionData(args)

  const [selected, setSelected] = useState(null)
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("list");
  const [group, setGroup] = useState("Completed");
  const [scanStats, setScanStats] = useState([])

  function handleViewChange(viewType) {
    setView(viewType)
  }

  function handleGroupChange(groupType) {
    setGroup(groupType)
  }

  function isSelectedView(id) {
    return (view == id) ? "primary" : "default"
  }

  function isSelectedGroup(id) {
    return (group == id) ? "primary" : "default"
  }

  function getGroupData() {
    var data;
    switch (group) {
    case "In progress":
      data = resource?.data?.inprogress;
      break;
    case "Scheduled":
      data = resource?.data?.scheduled;
      break;
    case "Archived":
      data = resource?.data?.archived;
      break;
    default:
      data = resource?.data?.completed;
      break;
    }

    return data;
  }

  function isListView() {
    return view === "list"
  }

  function onClickHandler(id) {
    setSelected(id)
    setOpen(true)
  }

  useEffect(() => {
    if (resource?.data) {

      const stats = [
        {
          title: "Completed",
          stat: resource?.data?.completed?.length | 0
        }, {
          title: "In progress",
          stat: resource?.data?.inprogress?.length | 0
        }, {
          title: "Scheduled",
          stat: resource?.data?.scheduled?.length | 0
        }, {
          title: "Archived",
          stat: resource?.data?.archived?.length | 0
        }
      ];

      setScanStats(stats)
    }
  }, [resource])

  function isDataEmpty() {
    return resource?.data?.completed === null && resource?.data?.inprogress === null &&
      resource?.data?.scheduled === null && resource?.data?.archived === null
  }

  return (
    <Page route="scans">
      <PageHeader>
        <Title>Scans - {group}</Title>
        <Toolbar>
          <ToolbarItem>
            <SlButtonGroup label="Views">
              <SlTooltip content="List view">
                <SlButton variant={isSelectedView("list")} size="medium" onClick={() => handleViewChange("list")}>
                  <SlIcon name="list"></SlIcon>
                </SlButton>
              </SlTooltip>
              <SlTooltip content="Card view">
                <SlButton variant={isSelectedView("card")} size="medium" onClick={() => handleViewChange("card")}>
                  <SlIcon name="card-text"></SlIcon>
                </SlButton>
              </SlTooltip>
            </SlButtonGroup>
          </ToolbarItem>
          <ToolbarItem>
            <SlButtonGroup label="ScanGroup">
              <SlTooltip content="Completed">
                <SlButton variant={isSelectedGroup("Completed")} size="medium" onClick={() => handleGroupChange("Completed")}>
                  <SlIcon name="check-circle"></SlIcon>
                </SlButton>
              </SlTooltip>
              <SlTooltip content="In progress">
                <SlButton variant={isSelectedGroup("In progress")} size="medium" onClick={() => handleGroupChange("In progress")}>
                  <SlIcon name="clock"></SlIcon>
                </SlButton>
              </SlTooltip>
              <SlTooltip content="Scheduled">
                <SlButton variant={isSelectedGroup("Scheduled")} size="medium" onClick={() => handleGroupChange("Scheduled")}>
                  <SlIcon name="clock-history"></SlIcon>
                </SlButton>
              </SlTooltip>
              <SlTooltip content="Archived">
                <SlButton variant={isSelectedGroup("Archived")} size="medium" onClick={() => handleGroupChange("Archived")}>
                  <SlIcon name="archive"></SlIcon>
                </SlButton>
              </SlTooltip>
            </SlButtonGroup>
          </ToolbarItem>
        </Toolbar>
      </PageHeader>
      <PageBody $m15>
        {
          isDataEmpty() && <NoData />
        }
        {
          !isDataEmpty() && (
            <>
              <FlexCols>
                <FlexItem>
                  <PannelSmall data={scanStats}></PannelSmall>
                </FlexItem>
                <FlexItem $m20>
                  {
                    isListView() ?
                      <Suspense fallback={<ScanList $suspense />}>
                        <ScanList data={getGroupData()} clickHandler={onClickHandler} />
                      </Suspense> :
                      <Suspense fallback={<ScanCards $suspense />}>
                        <ScanCards data={getGroupData()} clickHandler={onClickHandler} />
                      </Suspense>
                  }
                </FlexItem>
              </FlexCols>
              <ScanDetails sid={selected} open={open} setOpen={setOpen}/>
            </>
          )
        }
      </PageBody>
    </Page>
  )
}