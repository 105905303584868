import { FlexCols, FlexItem, FlexRows } from "lib/core/flex"
import styled from "styled-components"
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';
import SlBadge from '@shoelace-style/shoelace/dist/react/badge';

import useSessionData from "stores/useSessionData";
import { NoData } from "routes/main";

const StyledTitle = styled.h4`
  padding: 10px 0px;
`

const Panel = styled(FlexItem).attrs({
  $bd: true,
  $pd15: true,
  $fx: "1",
})`
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
`

export const Overview = () => {
  var args = {
    slice_name: "finding",
    store_name: "overview"
  }

  var resource = useSessionData(args)

  return (
    <>
      {
        (resource && resource?.data?.standards != null) && (
          <>
            <FlexCols $g20>
              <FlexRows $g20>
                <Panel>
                  {
                    (resource?.data?.standards[0]?.items?.length > 0) && (
                      <FlexCols>
                        <StyledTitle>{resource?.data?.standards[0].name}</StyledTitle>
                        <SlDivider /><br />
                        <FlexCols style={{ overflowY: "scroll", height: "32vh" }}>
                          {resource?.data?.standards[0].items.map((item, i) => (
                            <FlexRows key={i}>
                              <FlexItem $fx="1">&bull;&nbsp;&nbsp;{item.name}</FlexItem>
                              <FlexItem $fx="0.08">
                                <SlBadge variant="neutral">
                                  <span style={{ width: "30px", justifyContent: "space-evenly" }}>{item.count}</span>
                                </SlBadge>
                              </FlexItem>
                            </FlexRows>
                          ))}
                        </FlexCols>
                      </FlexCols>
                    )
                  }
                  {
                    <NoData criteria={resource?.data?.standards[0]?.items === null} />
                  }
                </Panel>
                <Panel>
                  {
                    (resource?.data?.standards[1]?.items?.length > 0) && (
                      <FlexCols>
                        <StyledTitle>{resource?.data?.standards[1].name}</StyledTitle>
                        <SlDivider /><br />
                        <FlexCols style={{ overflowY: "scroll", height: "32vh" }}>
                          {resource?.data?.standards[1].items.map((item, i) => (
                            <FlexRows key={i}>
                              <FlexItem $fx="1">&bull;&nbsp;&nbsp;{item.name}</FlexItem>
                              <FlexItem $fx="0.08">
                                <SlBadge variant="neutral">
                                  <span style={{ width: "30px", justifyContent: "space-evenly" }}>{item.count}</span>
                                </SlBadge>
                              </FlexItem>
                            </FlexRows>
                          ))}
                        </FlexCols>
                      </FlexCols>
                    )
                  }
                  {
                    <NoData criteria={resource?.data?.standards[1]?.items === null} />
                  }
                </Panel>
              </FlexRows>
              <FlexRows $g20>
                <Panel>
                  {
                    (resource?.data?.standards[2]?.items?.length > 0) && (
                      <FlexCols>
                        <StyledTitle>{resource?.data?.standards[2].name}</StyledTitle>
                        <SlDivider /><br />
                        <FlexCols style={{ overflowY: "scroll", height: "32vh" }}>
                          {resource?.data?.standards[2].items.map((item, i) => (
                            <FlexRows key={i}>
                              <FlexItem $fx="1">&bull;&nbsp;&nbsp;{item.name}</FlexItem>
                              <FlexItem $fx="0.08">
                                <SlBadge variant="neutral">
                                  <span style={{ width: "30px", justifyContent: "space-evenly" }}>{item.count}</span>
                                </SlBadge>
                              </FlexItem>
                            </FlexRows>
                          ))}
                        </FlexCols>
                      </FlexCols>
                    )
                  }
                  {
                    <NoData criteria={resource?.data?.standards[2]?.items === null} />
                  }
                </Panel>
                <Panel>
                  {
                    (resource?.data?.standards[3]?.items?.length > 0) && (
                      <FlexCols>
                        <StyledTitle>{resource?.data?.standards[3].name}</StyledTitle>
                        <SlDivider /><br />
                        <FlexCols style={{ overflowY: "scroll", height: "32vh" }}>
                          {resource?.data?.standards[3].items.map((item, i) => (
                            <FlexRows key={i}>
                              <FlexItem $fx="1">&bull;&nbsp;&nbsp;{item.name}</FlexItem>
                              <FlexItem $fx="0.08">
                                <SlBadge variant="neutral">
                                  <span style={{ width: "30px", justifyContent: "space-evenly" }}>{item.count}</span>
                                </SlBadge>
                              </FlexItem>
                            </FlexRows>
                          ))}
                        </FlexCols>
                      </FlexCols>
                    )
                  }
                  {
                    <NoData criteria={resource?.data?.standards[3]?.items === null} />
                  }
                </Panel>
              </FlexRows>
            </FlexCols>
          </>
        )
      }
      {
        <NoData criteria={resource === undefined || resource?.data?.standards === null} />
      }
    </>
  )
}
