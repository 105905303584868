import Chart from "react-apexcharts";
import { useTheme } from 'styled-components'

export default function Polararea(props) {
  const theme = useTheme()

  const options = {
    chart: {
      type: 'polarArea',
      foreColor: theme.color.text
    },
    labels: props.labels,
    dataLabels: {
      style: {
        fontWeight: 500,
        colors: [theme.color.text]
      },
      dropShadow: {
        enabled: false
      }
    },
    fill: {
      opacity: 1
    },
    stroke: {
      width: 1,
      colors: undefined
    },
    yaxis: {
      show: false
    },
    legend: {
      itemMargin: {
        vertical: 5
      },
      fontSize: 10
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0
        },
        spokes: {
          strokeWidth: 0
        },
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        shadeTo: 'light',
        shadeIntensity: 0.6
      }
    }
  }

  return (
    <Chart options={options} series={props.series} type="polarArea" height={200} width={310} />
  )
}