import styled from 'styled-components';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDivider from '@shoelace-style/shoelace/dist/react/divider';

import DonutChart2 from 'lib/charts/donut';

import useSessionData from "stores/useSessionData";
import { NoData } from "routes/main";

const StyledTitle = styled.h4`
  padding: 10px 0px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`

const StyledItems = styled.div`
  width: calc(95%/3);
  height: 330px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 6px;
  padding: 15px;
  // font-size: var(--sl-font-size-medium);
  box-shadow: ${({ theme }) => theme.color.panelBoxShadow};
`

const InnerContainer = styled.div`
  margin: 0px -10px;
`

export const Compliances = () => {
  var args = {
    slice_name: "finding",
    store_name: "compliance"
  }

  var resource = useSessionData(args)

  return (
    <StyledContainer>
      {
        (resource && resource?.data?.compliances) && (
          resource?.data?.compliances?.map((item, i) => {
            return (
              <StyledItems key={i}>
                <StyledTitle><SlIcon name="patch-check" />&nbsp; &nbsp; &nbsp;<strong>{item?.name.toUpperCase()}</strong></StyledTitle>
                <SlDivider /><br />
                <InnerContainer>
                  {
                    (() => {
                      const findings = item?.severity_score?.critical + item?.severity_score?.high + item?.severity_score?.medium + item?.severity_score?.low + item?.severity_score?.info
                      const critical = parseInt((item?.severity_score?.critical * 100) / findings)
                      const high = parseInt((item?.severity_score?.high * 100) / findings)
                      const medium = parseInt((item?.severity_score?.medium * 100) / findings)
                      const low = parseInt((item?.severity_score?.low * 100) / findings)
                      // const info = Number((item?.severity_score?.info * 100) / findings)

                      return <DonutChart2 series={[critical, high, medium, low]} />
                    })()
                  }
                </InnerContainer>
              </StyledItems>
            )
          })
        ) 
      }
      {
        <NoData criteria={resource === undefined || resource?.data?.compliances === null} />
      }
    </StyledContainer>
  )
}
